<template>
  <section>
    <b-modal
        id="evrak1"
        ref="modal1"
        centered
        size="xl"
        hide-footer
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('malzeme-talep')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Malzeme Talep Formu"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="malzeme-talep"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">MALZEME TALEP FORMU</h3>
            </b-col>
            <b-col cols="12">
              <h5 class="my-50 font-size-13">Belge Tarih Ve Sayısı : {{ bilgiler.belgeTarihSayi }}</h5>
            </b-col>
            <b-col cols="12">
              <h3 class="my-2 text-center font-size-17">
                {{ $store.getters.FIRMA_BILGILERI.tamUnvan }}
              </h3>
            </b-col>
            <b-col cols="9" class="border">
              <h5 class="my-50 text-center font-weight-bolder font-size-13">Satın Alınacak Malzemenin Cinsi</h5>
            </b-col>
            <b-col cols="3" class="border">
              <h5 class="my-50 text-center font-weight-bolder font-size-13">Miktarı</h5>
            </b-col>
            <b-col cols="9" class="border">
              <h5 class="my-50 font-size-14">{{ bilgiler.ihaleBaslik }}</h5>
            </b-col>
            <b-col cols="3" class="border font-size-14">
              <h5 class="my-50 text-center">{{ detay.isMiktar }}</h5>
            </b-col>
            <b-col cols="12">
              <h5 class="my-2 font-size-13">Kurumumuz {{ bilgiler.ilgiliBirim }} için yukarıdaki cins ve miktarı
                yazılı malzemenin
                satın alınmasını arz ederim.</h5>
              <hr/>
            </b-col>
            <b-col cols="6">
              <h6 class="my-50 text-center mx-5 font-size-13">Yukarıda belirtilen malzemelerin alınması için İhaleye
                çıkılması
                hususunu Olurlarınıza arz ederim.</h6>
              <h6 class="my-1 text-center font-size-13">{{ bilgiler.belgeTarih }}</h6>
              <h5 class="m-0 text-center font-size-14" v-if="evraklar(1).sira.sira1">{{
                  evraklar(1).sira.sira1.adSoyad
                }}</h5>
              <h6 class="text-center font-size-14" v-if="evraklar(1).sira.sira1">{{ evraklar(1).sira.sira1.unvan }}</h6>
            </b-col>
            <b-col cols="6">
              <h5 class="mt-1 mb-0 text-center font-size-13">Uygundur.</h5>
              <h6 class="m-0 text-center font-size-13" v-if="bilgiler.ihaleYetkilisi">{{ bilgiler.belgeTarih }}</h6>
              <h5 class="mt-1 mb-0 text-center font-size-14" v-if="bilgiler.ihaleYetkilisi">{{
                  bilgiler.ihaleYetkilisi
                }}</h5>
              <h6 class="m-0 text-center font-size-14" v-if="bilgiler.ihaleYetkilisi">{{ bilgiler.yetkiliUnvan }}</h6>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak2"
        ref="modal2"
        centered
        size="xl"
        hide-footer
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('satin-alma')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Satın Alma Komisyon Üyeleri"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="satin-alma"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">SATIN ALMA KOMİSYON ÜYELERİ</h3>
            </b-col>
            <b-col cols="12">
              <h5 class="my-2 text-center font-weight-bolder font-size-15">{{ $store.getters.FIRMA_BILGILERI.tamUnvan }}
                Doğrudan
                temin
                ile satın alınacak “{{ bilgiler.ihaleBaslik }}” satın alma komisyonu aşağıda belirtilen isimlerden
                oluşmuştur.
              </h5>
            </b-col>
            <b-col class="offset-6" cols="6" v-if="evraklar(2).sira.sira1">
              <h5 class="mt-1 mb-0 text-center font-size-13">{{ bilgiler.belgeTarih }}</h5>
              <h6 class="m-0 text-center text-decoration-underline font-size-14">İhale Yetkilisi</h6>
              <h5 class="mt-50 mb-0 text-center font-size-14">{{ bilgiler.ihaleYetkilisi }}</h5>
              <h6 class="m-0 text-center font-size-14">{{ bilgiler.yetkiliUnvan }}</h6>
            </b-col>
            <b-col cols="12" class="mt-5">
              <b-row>
                <b-col cols="2">
                  <h5 class="my-50 font-weight-bolder text-decoration-underline font-size-13">Görevi</h5>
                </b-col>
                <b-col cols="5">
                  <h5 class="my-50 font-weight-bolder text-decoration-underline font-size-13">Adı Soyadı</h5>
                </b-col>
                <b-col cols="3">
                  <h5 class="my-50 font-weight-bolder text-decoration-underline font-size-13">Unvanı</h5>
                </b-col>
                <b-col cols="2">
                  <h5 class="my-50 font-weight-bolder text-decoration-underline font-size-13">İmza</h5>
                </b-col>
                <b-col cols="12">
                  <b-row v-for="uye in lodash.orderBy(evraklar(2).evrakUyeleri, 'sira', 'asc')" :key="uye.siraNo">
                    <b-col cols="2">
                      <p class="my-50 font-size-14">{{ uye.gorev }}</p>
                    </b-col>
                    <b-col cols="5">
                      <p class="my-50 font-size-14">{{ uye.adSoyad }}</p>
                    </b-col>
                    <b-col cols="3">
                      <p class="my-50 font-weight-bolder font-size-14">{{ uye.unvan }}</p>
                    </b-col>
                    <b-col cols="2">
                      <p class="my-50 font-size-14"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak3"
        ref="modal3"
        centered
        size="xl"
        hide-footer
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('fiyat-arastirma')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Fiyat Araştırma Tespit Tutanağı"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="fiyat-arastirma"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">PİYASA FİYAT ARAŞTIRMA (TESPİT) TUTANAĞI </h3>
            </b-col>
            <b-col cols="12">
              <h5 class="font-size-13">
                Kurumumuz {{ $store.getters.FIRMA_BILGILERI.tamUnvan }} Müdürlüğü tarafından 4734 Sayılı Kamu İhale
                Kanununun 22/d maddesi gereğince, “{{ detay.ihaleUsulu }}” usulüyle satın alınması düşünülen aşağıda
                cinsi ve
                miktarı yazılı mal, hizmet ya da işin yaklaşık maliyetinin tespit edilmesinde değerlendirilmesi için;
              </h5>
            </b-col>
            <b-col cols="12">
              <h5 class="my-2 text-center font-size-13">
                KDV hariç olmak üzere, piyasa satış birim fiyatlarınızı toplamları ile birlikte bildirilmenizi rica
                ederiz.
              </h5>
            </b-col>
            <b-col v-for="uye in lodash.orderBy(evraklar(3).evrakUyeleri, 'sira', 'asc')" :key="uye.siraNo"
            >
              <h6 class="mb-1 text-center text-decoration-underline">{{ uye.gorev }}</h6>
              <p class="m-0 text-center font-weight-bolder">{{ uye.adSoyad }}</p>
              <p class="m-0 text-center font-weight-bold">{{ uye.unvan }}</p>
            </b-col>
            <b-col cols="12" class="mt-5">
              <b-row>
                <b-col cols="1" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    S. No
                  </h5>
                </b-col>
                <b-col cols="3" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Mal, Hizmet ya da
                    İşin Cinsi
                  </h5>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Miktarı ve Birimi
                  </h5>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    KDV
                    %’si
                  </h5>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="mb-0 text-center font-weight-bolder font-size-13">
                    Birim Fiyat TL
                  </h5>
                  <p class="mb-0 text-center">
                    (KDV Hariç)
                  </p>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="mb-0 text-center font-weight-bolder font-size-13">
                    Toplam Fiyat TL
                  </h5>
                  <p class="mb-0 text-center font-size-13">
                    (KDV Hariç)
                  </p>
                </b-col>
              </b-row>
              <b-row v-for="(item,i) in kalemler" :key="item.kalemID">
                <b-col cols="1" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{ i + 1 }}
                  </h6>
                </b-col>
                <b-col cols="3" class="border">
                  <h6 class="mb-0 p-50 font-size-14">
                    {{ item.kalemAdi }}
                  </h6>
                </b-col>
                <b-col cols="2" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{ item.miktar }} {{ item.birimTuru }}
                  </h6>
                </b-col>
                <b-col cols="2" class="border">
                  <h6 class="mb-0 p-50 text-center">

                  </h6>
                </b-col>
                <b-col cols="2" class="border">
                  <h6 class="mb-0 text-center">

                  </h6>
                </b-col>
                <b-col cols="2" class="border">
                  <h6 class="mb-0 text-center">

                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8" class="border">
                  <h5 class="mb-0 text-center p-1 font-size-14">
                    Genel Toplam Fiyat (KDV Hariç)
                  </h5>
                </b-col>
                <b-col cols="4" class="border">
                  <h5 class="mb-0 text-center p-1">

                  </h5>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="mt-5 mb-5">
              <h5 class="text-center font-size-13 mb-5">Yukarıda cinsi ve miktarı yazılı malzemelerin piyasa fiyatı
                ilgili
                kamu kurum ve
                kuruluşu, esnaf ve serbest piyasadan alınmıştır.</h5>
            </b-col>
            <b-col cols="12" class="mt-4 text-center mt-5">
              <h5 class="text-center font-weight-bolder d-inline-block font-size-14">Firma Adı </h5>
              <p class="d-inline-block pl-2">: .........................................................</p>
            </b-col>
            <b-col cols="12" class="mt-50 text-center">
              <h5 class="text-center font-weight-bolder d-inline-block font-size-14">Ticari Unvan</h5>
              <p class="d-inline-block pl-2">: .........................................................</p>
            </b-col>
            <b-col cols="12" class="mt-50 text-center">
              <h5 class="text-center font-weight-bolder d-inline-block font-size-14">Kaşe/İmza</h5>
              <p class="d-inline-block pl-2">: .........................................................</p>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak4"
        ref="modal4"
        centered
        size="xl"
        hide-footer
        v-if="teklifler.length > 0"
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('maliyet-hesaplama')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Yaklaşık Maliyet Hesaplama Tablosu"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="maliyet-hesaplama"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">YAKLAŞIK MALİYET HESAPLAMA TABLOSU</h3>
            </b-col>
            <b-col cols="3" class="border text-center p-0">
              <p class="font-weight-bolder font-size-13">Malzemenin Adı</p>
            </b-col>
            <b-col cols="1" class="border text-center p-0">
              <p class="font-weight-bolder font-size-13">Miktarı</p>
            </b-col>
            <b-col cols="4" class="border text-center">
              <p class="font-weight-bolder font-size-13">Özel</p>
              <b-row cols="12" class="border-top">
                <b-col
                    :cols="teklifler.length == 1 ? '12':teklifler.length == 2 ? '6':teklifler.length == 3?'4':teklifler.length == 4?'3':''"
                    class="border-right p-0" v-for="(item,i) in teklifler" :key="item.firmaID"
                >
                  <p class="font-weight-bolder m-0 font-size-14">
                    {{
                      i + 1
                    }}.Firma
                  </p>
                </b-col>
              </b-row>
              <b-row cols="12" class="border-top">
                <b-col
                    :cols="teklifler.length == 1 ? '12':teklifler.length == 2 ? '6':teklifler.length == 3?'4':teklifler.length == 4?'3':''"
                    class="border-right p-0" v-for="item in teklifler" :key="item.firmaID"
                >
                  <h6 v-html="formatFirmaBilgileri(item.firmaBilgileri)"
                      class=" font-weight-bolder p-50 pb-0 mb-0 font-size-14"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="2" class="border text-center p-0">
              <p class="font-weight-bolder font-size-13">Ortalama Fiyat</p>
            </b-col>
            <b-col cols="2" class="border text-center p-0">
              <p class="font-weight-bolder font-size-13">Nihai Fiyat</p>
            </b-col>
            <b-col cols="12">
              <b-row v-for="item in kalemler" :key="item.kalemID">
                <b-col cols="3" class="border border-top-0 border-bottom-1 p-0 pl-50">
                  <p class="font-weight-bolder m-0 font-size-14">{{ item.kalemAdi }}</p>
                </b-col>
                <b-col cols="1" class="border border-top-0 border-bottom-1 p-0">
                  <p class="font-weight-bolder text-center m-0 font-size-14">{{ item.miktar }}
                    {{ item.birimTuru }}</p>
                </b-col>
                <b-col cols="4" class="border-top-0">
                  <b-row>
                    <b-col
                        :cols="teklifler.length == 1 ? '12':teklifler.length == 2 ? '6':teklifler.length == 3?'4':teklifler.length == 4?'3':''"
                        v-for="(teklif,i) in teklifler" :key="teklif.firmaID"
                        class="border border-top-0 border-bottom-1 font-weight-bolder text-center p-0"
                    >
                      <p class="m-0 font-size-12">
                        {{
                          yaklasikMaliyetTeklif(item.kalemID, i).birimFiyat ?
                              Number(yaklasikMaliyetTeklif(item.kalemID, i).birimFiyat)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' TL' : ''
                        }}
                      </p>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="2" class="border border-top-0 border-bottom-1">
                  <p class="font-weight-bolder m-0 text-center font-size-12">{{
                      Number(ortalamaFiyat(item.kalemID))
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }}
                  </p>
                </b-col>
                <b-col cols="2" class="border border-top-0 border-bottom-1">
                  <p class="font-weight-bolder m-0 text-center font-size-12">
                    {{
                      Number(nihaiFiyat(item.kalemID))
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="10" class="border">
              <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-15">
                Toplam Fiyat TL
              </h5>
            </b-col>
            <b-col cols="2" class="border border-left-0 border-top-0">
              <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-15">
                {{
                  Number(totalFiyat())
                      .toFixed(2)
                      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                }}
              </h5>
            </b-col>
            <b-col v-for="uye in lodash.orderBy(evraklar(4).evrakUyeleri, 'sira', 'asc')" :key="uye.siraNo"
                   class="mt-5 mb-5"
            >
              <h6 class="mb-1 text-center text-decoration-underline font-size-14">{{ uye.gorev }}</h6>
              <p class="m-0 text-center font-weight-bolder font-size-14">{{ uye.adSoyad }}</p>
              <p class="m-0 text-center font-weight-bold font-size-14">{{ uye.unvan }}</p>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak5"
        ref="modal5"
        centered
        size="xl"
        hide-footer
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('ihale-onay')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="İhale Onay Belgesi"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="ihale-onay"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">İHALE ONAY BELGESİ</h3>
            </b-col>
            <b-col cols="3" class="border">
              <h5 class="my-50 font-size-13">İhaleyi Yapan Kurumun Adı</h5>
            </b-col>
            <b-col cols="9" class="border">
              <h5 class="my-50 font-size-14">{{ $store.getters.FIRMA_BILGILERI.tamUnvan }}</h5>
            </b-col>
            <b-col cols="3" class="border">
              <h5 class="my-50 font-size-13">Belge Tarih ve Sayısı</h5>
            </b-col>
            <b-col cols="9" class="border">
              <h5 class="my-50 font-size-14">{{ bilgiler.belgeTarihSayi }}</h5>
            </b-col>
            <b-col cols="12" class="border">
              <h3 class="text-center m-1 font-size-16">{{ $store.getters.FIRMA_BILGILERI.tamUnvan }}</h3>
            </b-col>
            <b-col cols="2" class="border">
              <h5 class="my-50 font-size-13">İşin Tanımı</h5>
            </b-col>
            <b-col cols="10" class="border">
              <h5 class="my-50 font-size-14">{{ detay.isTanim }}</h5>
            </b-col>
            <b-col cols="2" class="border">
              <h5 class="my-50 font-size-13">İşin Niteliği</h5>
            </b-col>
            <b-col cols="10" class="border">
              <h5 class="my-50 font-size-14">{{ detay.isNitelik }}</h5>
            </b-col>
            <b-col cols="2" class="border">
              <h5 class="my-50 font-size-13">İşin Miktarı</h5>
            </b-col>
            <b-col cols="10" class="border">
              <h5 class="my-50 font-size-14">{{ detay.isMiktar }}</h5>
            </b-col>
            <b-col cols="12" class="border">
              <h4 class="m-4"></h4>
            </b-col>
            <!--            <b-col cols="5" class="border">-->
            <!--              <h5 class="my-50">Kullanılabilir Ödenek Tutarı</h5>-->
            <!--            </b-col>-->
            <!--            <b-col cols="7" class="border">-->
            <!--              <h5 class="my-50 text-danger">Burası Ayarlanacak</h5>-->
            <!--            </b-col>-->
            <b-col cols="5" class="border">
              <h5 class="my-50 font-size-13">Yatırım Proje Numarası (varsa)</h5>
            </b-col>
            <b-col cols="7" class="border">
              <h5 class="my-50 font-size-14">{{ detay.projeNo }}</h5>
            </b-col>
            <b-col cols="5" class="border">
              <h5 class="my-50 font-size-13">Bütçe Tertibi (varsa)</h5>
            </b-col>
            <b-col cols="7" class="border">
              <h5 class="my-50 font-size-14">{{ detay.butceTertibi }}</h5>
            </b-col>
            <b-col cols="5" class="border">
              <h5 class="my-50 font-size-13">Avans Verilecekse Şartları</h5>
            </b-col>
            <b-col cols="7" class="border">
              <h5 class="my-50 font-size-14">{{ detay.avansSartlari }}</h5>
            </b-col>
            <b-col cols="5" class="border">
              <h5 class="my-50 font-size-13">İhale Usulü</h5>
            </b-col>
            <b-col cols="7" class="border">
              <h5 class="my-50 font-size-14">{{ detay.ihaleUsulu }}</h5>
            </b-col>
            <b-col cols="5" class="border">
              <h5 class="my-50 font-size-13">İlanın Şekli ve Adedi</h5>
            </b-col>
            <b-col cols="7" class="border">
              <h5 class="my-50 font-size-14">{{ detay.ilanSekliAdeti }}</h5>
            </b-col>
            <b-col cols="5" class="border">
              <h5 class="my-50 font-size-13">Ön Yeterlik/İhale Dokümanı Satış Bedeli</h5>
            </b-col>
            <b-col cols="7" class="border">
              <h5 class="my-50 font-size-14">{{ detay.dokumanSatisBedeli }}</h5>
            </b-col>
            <b-col cols="5" class="border">
              <h5 class="my-50 font-size-13">Fiyat Farkı Ödenecekse Dayanağı </h5>
            </b-col>
            <b-col cols="7" class="border">
              <h5 class="my-50 font-size-14">{{ detay.fiyatFarkDayanagi }}</h5>
            </b-col>
            <b-col cols="5" class="border">
              <h5 class="my-50 font-size-13">Bakanlar Kurulu Kararı</h5>
            </b-col>
            <b-col cols="7" class="border">
              <h5 class="my-50 font-size-14">{{ detay.bakanlarKuruluKarari }}</h5>
            </b-col>
            <b-col cols="5" class="border">
              <h5 class="my-50 font-size-13">İhale İle İlgili Diğer Açıklamalar</h5>
            </b-col>
            <b-col cols="7" class="border">
              <h5 class="my-50 font-size-14">{{ detay.digerAciklamalar }}</h5>
            </b-col>
            <b-col cols="12" class="border">
              <h2 class="m-1 text-center font-size-15">Onay</h2>
            </b-col>
            <b-col cols="6" class="border" v-if="evraklar(5).sira.sira1">
              <h6 class="my-50 text-center mx-5 font-size-13">Yukarıda belirtilen malzemelerin alınması için İhaleye
                çıkılması
                hususunu Olurlarınıza arz ederim.</h6>
              <h6 class="my-1 text-center font-size-14">{{ bilgiler.belgeTarih }}</h6>
              <h5 class="m-0 text-center font-size-14">{{ evraklar(5).sira.sira1.adSoyad }}</h5>
              <h6 class="text-center font-size-14">{{ evraklar(5).sira.sira1.unvan }}</h6>
            </b-col>
            <b-col cols="6" class="border d-grid" v-if="bilgiler.ihaleYetkilisi">
              <h5 class="mt-1 mb-0 text-center font-size-13">Uygundur.</h5>
              <h6 class="m-0 text-center font-size-14">{{ bilgiler.belgeTarih }}</h6>
              <h5 class="mt-1 mb-0 text-center font-size-14">{{ bilgiler.ihaleYetkilisi }}</h5>
              <h6 class="m-0 text-center font-size-14">{{ bilgiler.yetkiliUnvan }}</h6>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak6"
        ref="modal6"
        centered
        size="xl"
        hide-footer
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('birim-fiyat-mektubu')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Birim Fiyat Teklif Mektubu"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="birim-fiyat-mektubu"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-2 d-flex justify-content-end">
              <p class="text-center font-size-13">.... / .... / {{ currentYear }}</p>
            </b-col>
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">BİRİM FİYAT TEKLİF MEKTUBU</h3>
            </b-col>
            <b-col cols="12" class="mb-2">
              <h4 class="text-center font-size-16">{{ $store.getters.FIRMA_BILGILERI.tamUnvan }}
              </h4>
            </b-col>
            <b-col cols="12" class="mb-1">
              <h5 class="font-weight-bolder text-decoration-underline font-size-14">TEKLİF SAHİBİNİN</h5>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13">Adı Soyadı </h6>
            </b-col>
            <b-col cols="9">
              <p> : ............................................................................................</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13">Firma Unvanı / Uyruğu </h6>
            </b-col>
            <b-col cols="9">
              <p> : ............................................................................................</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13">Açık Tebligat Adresi </h6>
            </b-col>
            <b-col cols="9">
              <p> : ............................................................................................</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13">Bağlı Olduğu Vergi Dairesi</h6>
            </b-col>
            <b-col cols="9">
              <p> : ............................................................................................</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13">Vergi Numarası</h6>
            </b-col>
            <b-col cols="9">
              <p> : ............................................................................................</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13">Telefon ve Faks Numarası</h6>
            </b-col>
            <b-col cols="9">
              <p> : ............................................................................................</p>
            </b-col>
            <b-col cols="12" class="mt-2 mb-2">
              <p class="font-size-13"> Tarafınızdan ihale dokümanı oluşturan bütün belgeler incelenmiş, okunmuş ve
                herhangi bir ayrım ve
                sınırlama yapmadan bütün koşullarıyla kabul edilmiştir. İhaleye çıkarılan “E-Fatura Modülü ve
                Entegrasyonu” işine ilişkin olarak aşağıdaki hususları içeren teklifimizin kabulünü arz ederim.</p>
            </b-col>
            <b-col cols="12" class="p-50 font-size-13">1. Teklifimiz
              .................................................................................... tarihleri arasında
              geçerlidir.
            </b-col>
            <b-col cols="12" class="p-25 font-size-13">2. Teklifimize nakliye, taşıma, yerleştirme, istifleme ve kurulum
              gibi işler
              dahildir.
            </b-col>
            <b-col cols="12" class="p-25 font-size-13">3. İhale konsu iş için sermayesinin %50'sinden fazlasına sahip
              olduğumuz başka
              bir tüzel
              kişinin bu işe ayrı bir teklif vermediğini beyan ediyoruz.
            </b-col>
            <b-col cols="12" class="p-25 font-size-13">4. Aldığımız herhangi bir teklifi veya en düşün teklifi seçmek
              zorunda
              olmadığımızı kabul
              ediyoruz.
            </b-col>
            <b-col cols="12" class="p-25 font-size-13">5. İhale konusu iş ile ilgili olmak üzere idarenizce yaptırılacak
              diğer
              işlerde, idarenizin
              çıkarlarına aykırı düşecek hiçbir eylem ve oluşum içinde olmayacağımızı taahhüt ediyoruz.
            </b-col>
            <b-col cols="12" class="p-25 font-size-13">6. 4734 sayılı Kamu İhale Kanununu çerçevesinde yerli ya da
              yabancı istekli
              durumunda
              olduğumuzu beyan ediyoruz.
            </b-col>
            <b-col cols="12" class="p-25 font-size-13">7. Teklifimizde yer alan ürünlerin 4734 sayılı Kamu İhale
              Kanununu
              çerçevesinde yerli malı
              olup olmadığını beyan ediyoruz.
            </b-col>
            <b-col cols="12" class="p-25 font-size-13">8. İhale konusu mal, hizmet ya da iş, birim fiyatlar üzerinden
              teklif
              ettiğimiz, KDV hariç
              rakam ile ........................................ TL yazı ile
              ....................................................... TL
              toplam bedel olup, toplam bedelin ayrıntısı teklif mektubumuzun ekindeki cetveldedir.
            </b-col>
            <b-col cols="12" class="p-50 mb-1">Saygılarımızla;
            </b-col>
            <b-col cols="6" class="d-flex text-center justify-content-center">
              <h5 class="font-size-14">Eki- Birim Fiyat Teklif Cetveli</h5>
            </b-col>
            <b-col cols="6" class="justify-content-center d-flex text-center">
              <b-row>
                <b-col cols="12">
                  <h5 class="d-block font-size-14">Teklif Sahibi</h5>
                </b-col>
                <b-col cols="12">
                  <h5 class="d-block font-size-14">Firma-Kaşe-İmza</h5>
                </b-col>
              </b-row>
              <br/>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak7"
        ref="modal7"
        centered
        size="xl"
        hide-footer
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('birim-fiyat-cetveli')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Birim Fiyat Cetveli"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="birim-fiyat-cetveli"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">BİRİM FİYAT TEKLİF CETVELİ</h3>
            </b-col>
            <b-col cols="12" class="mb-1">
              <p class="font-weight-bolder font-size-14">Kurumumuz {{ $store.getters.FIRMA_BILGILERI.tamUnvan }}
                Müdürlüğü tarafından
                aşağıda cinsi, özellikleri ve miktarları yazılı mal, hizmet ya da iş; 4734 Sayılı Kamu İhale Kanunu'nun
                22/d maddesi gereğince {{ detay.ihaleUsulu }} usulüyle satın alınacaktır.
              </p>
              <p class="font-weight-bolder text-center font-size-14">İlgilenmeniz halinde KDV hariç teklif birim
                fiyatlarınızı
                toplamları ile birlikte bildirilmenizi rica
                ederiz.</p>
            </b-col>
            <b-col cols="12">
              <b-row>
                <b-col v-for="uye in lodash.orderBy(evraklar(7).evrakUyeleri, 'sira', 'asc')"
                       :key="uye.siraNo"
                       class="mt-1"
                >
                  <h6 class="mb-1 text-center text-decoration-underline font-size-13">{{ uye.gorev }}</h6>
                  <p class="m-0 text-center font-weight-bolder font-size-13">{{ uye.adSoyad }}</p>
                  <p class="m-0 text-center font-weight-bold font-size-13">{{ uye.unvan }}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-row>
                <b-col cols="1" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    S. No
                  </h5>
                </b-col>
                <b-col cols="3" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Mal, Hizmet ya da
                    İşin Cinsi
                  </h5>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Miktarı ve Birimi
                  </h5>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    KDV
                    %’si
                  </h5>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="mb-0 text-center font-weight-bolder font-size-13">
                    Birim Fiyat TL
                  </h5>
                  <p class="mb-0 text-center">
                    (KDV Hariç)
                  </p>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="mb-0 text-center font-weight-bolder font-size-13">
                    Toplam Fiyat TL
                  </h5>
                  <p class="mb-0 text-center">
                    (KDV Hariç)
                  </p>
                </b-col>
              </b-row>
              <b-row v-for="(item,i) in kalemler" :key="item.kalemID">
                <b-col cols="1" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{ i + 1 }}
                  </h6>
                </b-col>
                <b-col cols="3" class="border">
                  <h6 class="mb-0 p-50 font-size-14">
                    {{ item.kalemAdi }}
                  </h6>
                </b-col>
                <b-col cols="2" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{ item.miktar }} {{ item.birimTuru }}
                  </h6>
                </b-col>
                <b-col cols="2" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{ item.kdv }}
                  </h6>
                </b-col>
                <b-col cols="2" class="border">
                  <h6 class="mb-0 text-center font-size-14">
                    {{ item.birimFiyat }}
                  </h6>
                </b-col>
                <b-col cols="2" class="border">
                  <h6 class="mb-0 text-center font-size-14">
                    {{ item.toplamFiyat }}
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8" class="border">
                  <h5 class="mb-0 text-center p-1 font-size-15">
                    Genel Toplam Fiyat (KDV Hariç)
                  </h5>
                </b-col>
                <b-col cols="4" class="border">
                  <h5 class="mb-0 text-center p-1 text-danger">

                  </h5>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="pt-3">
              <h5 class="text-center font-size-15">VERİLECEK TEKLİF ŞARTLARI</h5>
            </b-col>
            <b-col cols="12" class="p-0">
              <p class="font-size-13 mb-0">
                1. Teklifler KDV hariç verilecek ve toplam fiyat üzerinden
                değerlendirilecektir.
              </p>
            </b-col>
            <b-col cols="12" class="p-0">
              <p class="font-size-13 mb-0">2. Kısmi teklif verilemez veya kısmi mal alınamaz. Kaşesi ya da adı soyadı,
                unvanı, adresi ve imzası olmayanlar ile silinti, kazıntı olan teklifler değerlendirilmeyecektir.
              </p>
            </b-col>
            <b-col cols="12" class="p-0">
              <p class="font-size-13 mb-0">3. Kurumumuza alınacak mal, hizmet ya da iş standartlara uygun olacak ve
                komisyonumuz tarafından sayımı, muayenesi ve uygunluk kontrolleri yapılacaktır uygun olmayan mal hizmet
                ya
                da iş kabul edilmeyecektir. Uygun olmayan ürünlerin gerilmesinin tekrarı halinde ise ihale tek taraflı
                olarak fesh edilecektir.
              </p>
            </b-col>
            <b-col cols="12" class="p-0">
              <p class="font-size-13 mb-0">4. Kurumumuza günlük gelecek olan perakende malzemeler sipariş fişi ile
                birlikte gelecek ve görevlilere imza karşılığı teslim edilecektir.
              </p>
            </b-col>
            <b-col cols="12" class="p-0">
              <p class="font-size-13 mb-0">5. Günlük sipariş fişleri ile gelen perakende malzemelerin faturası teklif
                edilen fiyat üzerinden ayda bir gerekirse 15 günde bir kesilecek ve peşin ödenecektir. Toptan gelen
                malzemelern ise tamamı teslim alındıktan sonra teklif edilen fiyat üzerinden faturası kesilecek ve peşin
                ödenecektir.</p>
            </b-col>
            <b-col cols="12" class="p-0">
              <p class="font-size-13 mb-0">6. Perakende alımlarda piyasa fiyatlarındaki değişkenlikler nedeniyle gerekli
                görüldüğü taktirde önceden haber verilmesi şartıyla teklif edilen toplam fiyat dolmasa da ihale tek
                taraflı olarak yeniden tazelenmek üzere fesh edilebilir. Aksi taktirde ise teklif edilen toplam fiyatın
                dolumunda ihale sona erecektir.
              </p>
            </b-col>
            <b-col cols="6" class="justify-content-center offset-6 d-flex text-center pt-2">
              <b-row>
                <b-col cols="12">
                  <h5 class="d-block font-size-14">Teklif Sahibi</h5>
                </b-col>
                <b-col cols="12">
                  <h5 class="d-block font-size-14">Firma-Kaşe-İmza</h5>
                </b-col>
              </b-row>
              <br/>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak8"
        ref="modal8"
        centered
        size="xl"
        hide-footer
        v-if="teklifler.length != 0"
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('yaklasik-maliyet')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Yaklaşık Maliyet Hesaplama Tablosu"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="yaklasik-maliyet"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">YAKLAŞIK MALİYET HESAP CETVELİ</h3>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-row>
                <b-col cols="4" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Birim Fiyata Esas İş Kaleminin Adı
                  </h5>
                </b-col>
                <b-col cols="2" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Miktarı
                  </h5>
                </b-col>
                <b-col cols="3" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Birim Fiyatı (TL)
                  </h5>
                </b-col>
                <b-col cols="3" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Tutarı (TL)
                  </h5>
                </b-col>
              </b-row>
              <b-row v-for="item in kalemler" :key="item.kalemID">
                <b-col cols="4" class="border">
                  <h6 class="mb-0 p-50 font-size-14">
                    {{ item.kalemAdi }}
                  </h6>
                </b-col>
                <b-col cols="2" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{ item.miktar }} {{ item.birimTuru }}
                  </h6>
                </b-col>
                <b-col cols="3" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{
                      Number(ortalamaFiyatBirim(item.kalemID))
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }} TL
                  </h6>
                </b-col>
                <b-col cols="3" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{
                      Number(ortalamaFiyatToplam(item.kalemID))
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }} TL
                  </h6>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8" class="border">
                  <h5 class="mb-0 text-center p-50 font-size-14">
                    Genel Toplam Fiyat (KDV Hariç)
                  </h5>
                </b-col>
                <b-col cols="4" class="border">
                  <h5 class="mb-0 text-center p-50 font-size-14">
                    {{
                      Number(genelToplamKDVHaric())
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }} TL
                  </h5>
                </b-col>
                <b-col cols="8" class="border">
                  <h5 class="mb-0 text-center p-50 font-size-14">KDV % 18</h5>
                </b-col>
                <b-col cols="4" class="border">
                  <h5 class="mb-0 text-center p-50 font-size-14">
                    {{
                      Number(ortalamaKDV())
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }} TL
                  </h5>
                </b-col>
                <b-col cols="8" class="border">
                  <h5 class="mb-0 text-center p-50 font-size-14">Genel Toplam (KDV dahil)</h5>
                </b-col>
                <b-col cols="4" class="border">
                  <h5 class="mb-0 text-center p-50 font-size-14">
                    {{
                      Number(ortalamaKDVDahil())
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }} TL
                  </h5>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="p-5 text-center font-size-14">Yukarıda cins ve miktarı yazılı mal ve malzemelerin
              yaklaşık
              maliyeti piyasa araştırılması yapılarak hesaplanmıştır. {{ bilgiler.belgeTarih }}
            </b-col>
            <b-col cols="12" class="pb-5 pt-4">
              <b-row>
                <b-col v-for="uye in lodash.orderBy(evraklar(8).evrakUyeleri, 'sira', 'asc')"
                       :key="uye.siraNo"
                       class="mt-1"
                >
                  <h6 class="mb-1 text-center text-decoration-underline font-size-14">{{ uye.gorev }}</h6>
                  <p class="m-0 text-center font-weight-bolder font-size-14">{{ uye.adSoyad }}</p>
                  <p class="m-0 text-center font-weight-bold font-size-14">{{ uye.unvan }}</p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak9"
        ref="modal9"
        centered
        size="xl"
        hide-footer
        v-if="teklifler.length != 0"
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('katilanlari-belirleme')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="İhaleye Katılanları Belirleme"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="katilanlari-belirleme"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">İHALEYE KATILANLARI BELİRLEME TUTANAĞI</h3>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder m-0 m-0 font-size-13">İhale Kayıt No</h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ bilgiler.belgeTarihSayi }}</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-size-13 font-weight-bolder m-0">İdarenin Adı </h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ $store.getters.FIRMA_BILGILERI.tamUnvan }}</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-size-13 font-weight-bolder m-0">İşin Adı</h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ bilgiler.ihaleBaslik }}</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-size-13 font-weight-bolder m-0">Formun Doldurulduğu Tarih</h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ bilgiler.belgeTarih }}</p>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-row>
                <b-col cols="1" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    S. No
                  </h5>
                </b-col>
                <b-col cols="8" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Teklif Verenlerin Adı Soyadı ve Unvanı
                  </h5>
                </b-col>
                <b-col cols="3" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Teklif Bedeli
                  </h5>
                </b-col>
              </b-row>
              <b-row v-for="(item,i) in teklifler" :key="item.atamaID">
                <b-col cols="1" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{ i + 1 }}
                  </h6>
                </b-col>
                <b-col cols="8" class="border">
                  <h6 class="mb-0 p-50 font-weight-bolder font-size-14">
                    {{ item.firmaBilgileri.firmaUnvan || item.firmaBilgileri.firmaAdi }}
                  </h6>
                </b-col>
                <b-col cols="3" class="border">
                  <h6 class="mb-0 p-50 text-center font-weight-bolder font-size-14">
                    {{ lodash.sum(lodash.flatMap(item.teklifler, 'toplamFiyat'), o => +o) }} TL
                  </h6>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="p-3 text-center">
              <p class="font-size-13">{{ bilgiler.belgeTarih }} tarihinde yapılan ihaleye {{ teklifler.length }}
                ({{
                  teklifler.length == 0 ? 'sıfır' : teklifler.length == 1 ? 'bir' : teklifler.length == 2 ? 'iki' : teklifler.length == 3 ? 'üç' : teklifler.length == 4 ? 'dört' : teklifler.length == 5 ? 'beş' : ''
                }}) istekli
                katılmış olup, teklif verenlerin adı soyadı ve unvanı yukarıda belirtilmiştir.
              </p>
            </b-col>
            <b-col cols="12" class="p-2 text-center">
              <h4 class="font-size-15">İHALE KOMİSYONU</h4>
            </b-col>
            <b-col cols="12" class="pb-5 pt-3">
              <b-row>
                <b-col v-for="uye in lodash.orderBy(evraklar(9).evrakUyeleri, 'sira', 'asc')"
                       :key="uye.siraNo"
                       class="mt-1"
                >
                  <h6 class="mb-1 text-center text-decoration-underline font-size-13">{{ uye.gorev }}</h6>
                  <p class="m-0 text-center font-weight-bolder font-size-13">{{ uye.adSoyad }}</p>
                  <p class="m-0 text-center font-weight-bold font-size-13">{{ uye.unvan }}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="text-center pt-3">
              <p class="p-0 m-0 font-size-14">Bilgim Vardır.</p>
            </b-col>
            <b-col cols="12" class="text-center ">
              <p class="p-0 m-0 font-size-14">{{ bilgiler.belgeTarih }}</p>
            </b-col>
            <b-col cols="12" class="text-center " v-if="bilgiler.ihaleYetkilisi">
              <p class="pt-50 m-0 font-weight-bolder font-size-14">{{ bilgiler.ihaleYetkilisi }}</p>
            </b-col>
            <b-col cols="12" class="text-center ">
              <p class="p-0 m-0 font-size-14">{{ bilgiler.yetkiliUnvan }}</p>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak10"
        ref="modal10"
        centered
        size="xl"
        hide-footer
        v-if="enAvantajliTeklif && teklifler.find(x => x.firmaBilgileri.firmaID == bilgiler.firmaID)"
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('karar-tutanagi')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="İhale Komisyon Karar Tutanağı"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="karar-tutanagi"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-1">
              <h3 class="text-center font-size-17 mt-4">İHALE KOMİSYON KARAR TUTANAĞI</h3>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder m-0 m-0 font-size-13">İhale Kayıt No</h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ bilgiler.belgeTarihSayi }}</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13 m-0">İdarenin Adı </h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ $store.getters.FIRMA_BILGILERI.tamUnvan }}</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13 m-0">İşin Adı</h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ bilgiler.ihaleBaslik }}</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13 m-0">İhale Tarih ve Saati</h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ bilgiler.kayitTarih.slice(0, 16) }} </p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13 m-0">İhale Usulü</h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ detay.ihaleUsulu }} </p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13 m-0">Toplam Teklif Sayısı</h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ teklifler.length }}
                ({{
                  teklifler.length === 1 ? 'Bir' : teklifler.length === 2 ? 'İki' : teklifler.length === 3 ? 'Üç' : teklifler.length === 4 ? 'Dört' : 'Beş' || ''
                }})</p>
            </b-col>
            <b-col cols="3">
              <h6 class="font-weight-bolder font-size-13 m-0">Geçerli Teklif Sayısı</h6>
            </b-col>
            <b-col cols="9">
              <p class="m-0 font-size-14"> : {{ teklifler.length }}
                ({{
                  teklifler.length === 1 ? 'Bir' : teklifler.length === 2 ? 'İki' : teklifler.length === 3 ? 'Üç' : teklifler.length === 4 ? 'Dört' : 'Beş' || ''
                }})</p>
            </b-col>
            <b-col cols="6" class="mt-1">
              <b-row v-if="enAvantajliTeklif[0]">
                <b-col cols="12">
                  <h5 class="text-decoration-underline font-size-14">
                    Ekonomik Açıdan En Avantajlı Teklifin
                  </h5>
                  <p class="d-inline-block m-0 font-size-13">
                    a) Sahibinin Adı :
                  </p>
                  <p class="font-weight-bolder d-inline-block m-0 pl-2 font-size-13">
                    {{ enAvantajliTeklif[0].name }}
                  </p>
                </b-col>
                <b-col cols="12">
                  <p class="d-inline-block m-0 font-size-13">
                    b) Tutarı :
                  </p>
                  <p class="font-weight-bolder m-0 d-inline-block pl-2 font-size-13">
                    {{
                      Number(enAvantajliTeklif[0].teklif)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }} TL
                  </p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6" class="mt-1">
              <b-row v-if="enAvantajliTeklif[1]">
                <b-col cols="12">
                  <h5 class="text-decoration-underline font-size-14">
                    Ekonomik Açıdan En Avantajlı İkinci Teklifin
                  </h5>
                  <p class="d-inline-block m-0 font-size-13">
                    a) Sahibinin Adı :
                  </p>
                  <p class="font-weight-bolder d-inline-block m-0 pl-2 font-size-13">
                    {{ enAvantajliTeklif[1].name }}
                  </p>
                </b-col>
                <b-col cols="12" v-if="enAvantajliTeklif[1].name">
                  <p class="d-inline-block m-0 font-size-13">
                    b) Tutarı :
                  </p>
                  <p class="font-weight-bolder m-0 d-inline-block pl-2 font-size-13">
                    {{
                      Number(enAvantajliTeklif[1].teklif)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }} TL
                  </p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="pt-1">
              <p class="font-size-13">Bu tutanağın düzenlendiği tarih ve saat : {{
                  bilgiler.kayitTarih.slice(0, 16)
                }}</p>
            </b-col>
            <b-col cols="12" class="pt-1">
              <h4 class="text-center font-size-15">İSTEKLİLERİN TEKLİF ETTİĞİ BEDELLER</h4>
            </b-col>
            <b-col cols="12" class="mt-1">
              <b-row>
                <b-col cols="8" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    İsteklinin Adı
                  </h5>
                </b-col>
                <b-col cols="4" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Teklif Edilen Bedel (TL)
                  </h5>
                </b-col>
              </b-row>
              <b-row v-for="(item,i) in enAvantajliTeklif" :key="i">
                <b-col cols="8" class="border">
                  <h6 class="mb-0 p-50 font-weight-bolder font-size-14">
                    {{ item.name }}
                  </h6>
                </b-col>
                <b-col cols="4" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{
                      Number(item.teklif)
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    }} TL
                    <!--                    {{-->
                    <!--                      Number(item.telif)-->
                    <!--                          .toFixed(2)-->
                    <!--                          .replace(/\d(?=(\d{3})+\.)/g, '$&,')-->
                    <!--                    }}-->
                  </h6>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="p-1">
              <p class="font-size-13">Kurumumuz {{ $store.getters.FIRMA_BILGILERI.tamUnvan }} Müdürlüğüne
                “{{
                  bilgiler.ihaleBaslik
                }}” satın
                almak üzere ihale edilmiş olup, “{{
                  bilgiler.ihaleBaslik
                }}” İhalesine {{ teklifler.length }}
                ({{
                  teklifler.length === 1 ? 'Bir' : teklifler.length === 2 ? 'İki' : teklifler.length === 3 ? 'Üç' : teklifler.length === 4 ? 'Dört' : 'Beş' || ''
                }}) İstekli teklif zarfı
                vermiştir. Alınan teklif zarfları istekliler ve Komisyonumuz huzurunda incelenerek {{
                  teklifler.length
                }}
                ({{
                  teklifler.length === 1 ? 'Bir' : teklifler.length === 2 ? 'İki' : teklifler.length === 3 ? 'Üç' : teklifler.length === 4 ? 'Dört' : 'Beş' || ''
                }}) teklif zarfı
                geçerli sayılmış, geçerli sayılan teklif zarfları istekliler ve Komisyonumuz huzurunda açılarak, istenen
                belgeler ve teklif fiyatları incelenerek idari ve teknik şartnameye göre geçerli olanlar kabul
                edilmiştir. Geçerli tekliflerden ekonomik açıdan en avantajlı iki teklif yukarıda belirtilmiştir.
                Birinci teklifin ekonomik açıdan en avantajlı teklif olması nedeniyle en düşük fiyat esasına göre
                birinci teklif sahibinden
                "{{
                  teklifler.find(x => x.firmaBilgileri.firmaID == bilgiler.firmaID).firmaBilgileri.firmaUnvan
                }}"
                nden satın alınmasına
                komisyonumuzca karar
                verilerek, ita amirinin onayına arz olundu. {{ bilgiler.belgeTarih }}
              </p>
            </b-col>
            <b-col cols="12" class="pt-50">
              <b-row>
                <b-col v-for="uye in lodash.orderBy(evraklar(9).evrakUyeleri, 'sira', 'asc')"
                       :key="uye.siraNo"
                >
                  <h6 class="mb-1 text-center text-decoration-underline font-size-14">{{ uye.gorev }}</h6>
                  <p class="m-0 text-center font-weight-bolder font-size-14">{{ uye.adSoyad }}</p>
                  <p class="m-0 text-center font-weight-bold font-size-14">{{ uye.unvan }}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="text-center pt-1">
              <p class="p-0 m-0 font-size-14">Uygundur.</p>
            </b-col>
            <b-col cols="12" class="text-center">
              <p class="p-0 m-0 font-size-14">{{ bilgiler.belgeTarih }}</p>
            </b-col>
            <b-col cols="12" class="text-center" v-if="bilgiler.ihaleYetkilisi">
              <p class="pt-50 m-0 font-weight-bolder font-size-14">{{ bilgiler.ihaleYetkilisi }}</p>
            </b-col>
            <b-col cols="12" class="text-center" v-if="bilgiler.yetkiliUnvan">
              <p class="p-0 m-0 font-size-14">{{ bilgiler.yetkiliUnvan }}</p>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak11"
        ref="modal11"
        centered
        size="xl"
        hide-footer
        v-if="teklifler.find(x => x.firmaBilgileri.firmaID == bilgiler.firmaID)"
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('temin-sozlesmesi')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Doğrudan Temin Sözleşmesi"
          :pdf-quality="2"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="temin-sozlesmesi"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="2"/>
            <b-col cols="8" class="mb-2">
              <h4 class="text-center font-size-17">
                4734 SAYILI KAMU İHALE KANUNUN 22/D
                {{ detay.ihaleUsulu }} USULÜ İLE ALIMA AİT SÖZLEŞME
                ( Mal Alımı Sözleşmesi )
              </h4>
            </b-col>
            <b-col cols="2"/>
            <b-col cols="12">
              <h5 class="font-weight-bolder font-size-14">Madde 1 – Sözleşmenin Tarafları</h5>
            </b-col>
            <b-col cols="12">
              <p>Bu sözleşme, bir tarafta {{ $store.getters.FIRMA_BILGILERI.unvan }} MÜDÜRLÜĞÜ
                (bundan sonra “İdare”
                olarak anılacaktır.)
                ile diğer tarafta
                {{
                  teklifler.find(x => x.firmaBilgileri.firmaID == bilgiler.firmaID).firmaBilgileri.firmaAdi
                }}
                (bundan sonra “Yüklenici” olarak anılacaktır.) arasında
                aşağıda yazılı şartlar dahilinde akdedilmiştir.</p>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder font-size-14">Madde 2 –Taraflara İlişkin Bilgiler</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="font-weight-bolder">2.1. İdarenin Adresi : </h6>
            </b-col>
            <b-col cols="12">
              <p class="mb-50">{{ $store.getters.FIRMA_BILGILERI.adres }}</p>
              <b-col cols="12">
                <b-row>
                  <b-col cols="3" class="p-0">
                    <h6 class="m-0 m-0">Telefon / Faks</h6>
                  </b-col>
                  <b-col cols="9" class="p-0">
                    <h6 class="font-weight-bolder m-0 m-0"> : {{ $store.getters.FIRMA_BILGILERI.telefon }} </h6>
                  </b-col>
                  <b-col cols="3" class="p-0">
                    <h6 class="m-0 m-0">E-Posta Adresi </h6>
                  </b-col>
                  <b-col cols="9" class="p-0">
                    <h6 class="font-weight-bolder m-0 m-0"> : {{ $store.getters.FIRMA_BILGILERI.ePosta }} </h6>
                  </b-col>
                </b-row>
              </b-col>
            </b-col>
            <b-col cols="12">
              <h6 class="font-weight-bolder pt-1">2.2. Yüklenicinin Tebligat Adresi : </h6>
            </b-col>
            <b-col cols="12">
              <p class="mb-50">
                {{ teklifler.find(x => x.firmaBilgileri.firmaID == bilgiler.firmaID).firmaBilgileri.firmaAdres }}
              </p>
              <b-col cols="12">
                <b-row>
                  <b-col cols="3" class="p-0">
                    <h6 class="m-0 m-0">Tel. No.</h6>
                  </b-col>
                  <b-col cols="9" class="p-0">
                    <h6 class="font-weight-bolder m-0 m-0"> :
                      {{
                        teklifler.find(x => x.firmaBilgileri.firmaID == bilgiler.firmaID).firmaBilgileri.firmaTelefon
                      }}
                    </h6>
                  </b-col>
                  <!--                  <b-col cols="3" class="p-0">-->
                  <!--                    <h6 class="m-0 m-0">Fax. No.</h6>-->
                  <!--                  </b-col>-->
                  <!--                  <b-col cols="9" class="p-0">-->
                  <!--                    <h6 class="font-weight-bolder m-0 m-0"> : {{ teklifler.find(x => x.firmaBilgileri.firmaID == bilgiler.firmaID).firmaBilgileri.faxNo }} </h6>-->
                  <!--                  </b-col>-->
                </b-row>
              </b-col>
            </b-col>
            <b-col cols="12">
              <h6 class="">2.3. Her iki taraf madde 2.1 ve 2.2 de belirtilen adreslerini tebligat
                adresleri olarak kabul etmişlerdir. Adres değişiklikleri usulüne uygun şekilde karşı tarafa tebliğ
                edilmedikçe en son bildirilen adrese yapılacak tebliğ ilgili tarafa yapılmış sayılır.</h6>
            </b-col>
            <b-col cols="12">
              <h5 class=" font-weight-bolder font-size-14">Madde 3 –Sözleşmenin Konusu </h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">3.1.Sözleşme konusu iş; İdarenin ihtiyacı olan {{ bilgiler.ihaleBaslik }}
                sözleşmede
                belirlenen şartlar dahilinde Yüklenici tarafından sağlanması işidir. İşin teknik ayrıntıları ve
                ifasına
                ilişkin detaylar sözleşme ek’inde yer alan ve ihale dokümanını oluşturan belgelerle
                düzenlenmiştir.</h6>
            </b-col>
            <b-col cols="12">
              <h6 class="">3.2. Bu sözleşme ile temin edilecek mal yüklenici tarafından teklif edilen ve idare
                tarafından uygun bulunan /beğenilen numuneye / teknik özelliklere göre teslim edilecektir.</h6>
            </b-col>
            <b-col cols="12">
              <h5 class=" font-weight-bolder font-size-14">Madde 4 – Sözleşmenin Türü Ve Bedeli </h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Üzerine ihale yapılan istekliyle, her bir iş kaleminin miktarı ile bu iş kalemleri
                için
                teklif edilen birim fiyatların çarpımı sonucu bulunan toplam bedel üzerinden birim fiyat sözleşme
                imzalanacaktır .Sözleşme bedeli
                6.000,00 TL bedel üzerinden akdedilmiştir.
              </h6>
            </b-col>
            <b-col cols="12">
              <h5 class=" font-weight-bolder font-size-14">Madde 5 – Vergi, Resim Ve Harçlar İle Sözleşmeyle
                İlgili
                Diğer
                Giderler</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Taahhüdün yerine getirilmesine ilişkin ulaşım, sigorta, vergi (KDV hariç) resim ve
                harçlar sözleşme bedeline dahildir. İlgili mevzuatı uyarınca hesaplanacak Katma Değer Vergisi sözleşme
                bedeline dahil olmayıp idare tarafından yükleniciye ödenecektir. Ancak 3065 Sayılı Kanunun 13/f
                maddesi
                kapsamına giren işler (Maliye Bakanlığı Gelirler Genel Müdürlüğünce yayımlanan KDV Genel Tebliği
                1.4.3.1. bölümünde yapılan açıklamalar gereği) KDV muafiyeti içerdiğinden bu kapsama giren alımlar
                için
                Katma Değer Vergisi ödenmeyecek olup, ödeme aşamasında yükleniciye bu yönde belge verilecektir.</h6>
            </b-col>
            <b-col cols="12">
              <h5 class=" font-weight-bolder font-size-14">Madde 6 – Malın/İşin Teslim Alma Şekil Ve Şartları İle
                Teslim
                Programı</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="pt-1 font-weight-bolder">6.1. İşin Yapılma / Malın Teslim Edilme Yeri </h6>
            </b-col>
            <b-col cols="12">
              <h6 class=""><p class="m-0 d-inline-block">{{ $store.getters.FIRMA_BILGILERI.tamUnvan }}
                MÜDÜRLÜĞÜ</p>
                İdaresinin göstereceği yere teslim edilecektir.
              </h6>
            </b-col>
            <b-col cols="12">
              <h6 class="">6.2. İşe Başlama Tarihi : Sözleşmenin imzalanmasından itibaren idarenin siparişine
                göre
                teslim başlayacaktır. {{ bilgiler.ihaleBaslik }} kurum olarak ihtiyaç duyuldukça alım
                gerçekleştirilecek ve ödemesi yapılacaktır.
              </h6>
            </b-col>
            <b-col cols="12">
              <h6 class="">6.3. Teslim Şekli : Teslimat {{ bilgiler.ihaleBaslik }} ihtiyaç duyuldukça idare
                tarafından istenecek yüklenici tarafından temin edilecek ve idare tarafından 30 gün içerisinde ödemesi
                yapılacaktır. {{ bilgiler.ihaleBaslik }} nakliyesi ve taşıması yüklenici tarafından
                yapılacaktır.</h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 7 –Ödeme Yeri Ve Şartları</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Bu sözleşmede düzenlenen hata, kusur ve eksikliklere ilişkin hükümler saklı kalmak
                kaydıyla ,teslim edilen malların / işin bedeli, idarenin kesin kabulü ve yüklenicinin faturayı idareye
                teslim etmesini müteakiben düzenlenecek ödeme belgelerine istinaden, Türk Lirası olarak ve Maliye
                Bakanlığınca belirlenen usul ve esaslar dahilinde {{ $store.getters.FIRMA_BILGILERI.unvan }} muhasebe
                bölümünden
                yapılacaktır. </h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 8–Yüklenicinin Ceza Sorumluluğu</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">
                İş tamamlandıktan ve kabul işlemi yapıldıktan sonra tespit edilmiş olsa dahi 4734 sayılı Kanunun 17
                inci
                ve 4735 sayılı Kanunun 25 inci maddesinde belirtilen yasak fiil ve davranışlardan Türk Ceza Kanununa
                göre suç teşkil eden fiil ve davranışlarda bulunan Yüklenici, ortak ve vekilleri hakkında yetkili
                Cumhuriyet Savcılığına suç duyurusunda bulunulur.
              </h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 9 – Gecikme Cezası</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Yüklenici, sözleşmeye uygun olarak malı süresinde teslim etmediği / işi bitirmediği
                takdirde; gecikilen her takvim günü için sözleşme bedelinin binde üçü (3) oranında gecikme cezasına
                tabi
                olacaktır. Ancak bu gecikmenin on(10) günü aşması durumunda ayrıca ihtar / protesto çekmeye gerek
                kalmaksızın sözleşmeyi fesh edip etmemek idarenin etkisindedir.</h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 10 – Malların Taşınması</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Bu sözleşmede düzenlenen işlerin yapılmasına ilişkin her türlü taşıma gideri (kargo)
                sözleşme bedeline dahildir.</h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 11 – Ambalajlama</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Sözleşme konusu mal, örnek numunesinde / idare tarafından belirtilen şekilde orijinal
                ambalajında teslim edilecektir. Malın orijinal olarak ambalajlanmaması nedeniyle meydana gelebilecek
                ve
                sigorta tarafından karşılanmayan hasar, zarar ve eksiklikler Yükleniciye ait olacaktır. </h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 12 – Mevzuata Uygunluk</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Yüklenici, ilgili bütün ihbarların verilmesi ve bütün ödemelerin yapılması da dahil
                olmak üzere,</h6>
              <h6 class=" pl-2">a – İşin yürütülmesine ve tamamlanmasına ve malda olabilecek kusurların
                düzeltilmesine
                ilişkin olarak bütün kanun, yönetmelikler, tüzükler, kararnameler, genelge, tebliğ ve diğer ilgili
                mevzuata,</h6>
              <h6 class=" pl-2">b – Kamu kurum ve kuruluşlarının alacağı kararlar ile Mahkeme kararlarına, uyacak
                ve
                İdareyi bu hükümlerin ihlali nedeniyle maruz kalabileceği bütün cezalardan ve sorumluluklardan dolayı
                tazmin edecektir. </h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 13 – Yüklenicinin Tazmin Sorumluluğu</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Yüklenici, garanti süresi boyunca oluşabilecek arızalardan sorumludur, taahhüdü
                çerçevesinde kusurlu veya standartlara uygun olmayan mal temini, teslimi veya kullanılması, taahhüdün
                sözleşme ve şartname hükümlerine uygun olarak yerine getirilmemesi ve benzeri nedenlerle ortaya çıkan
                zarar ile üçüncü kişilere, çevreye veya idare personeline verilen zarar ve ziyanlardan doğrudan
                sorumludur. Ayrıca haklarında 4735 sayılı kanunun 27 nci maddesi hükümleri uygulanır. Garanti
                hükümlerinin uygulanması ile ilgili ortaya çıkacak ihtilaflar, 28/05/2014 tarih ve 6502 sayılı
                TÜKETİCİNİN KORUNMASI hakkında kanun hükümleri uyarınca ilgili mahkemelerde sonuca
                bağlanacaktır. </h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 14 – Alımı yapılacak mallar ve miktarları</h5>
            </b-col>
            <b-col cols="12">
              <b-row v-for="(item,i) in kalemler" :key="item.kalemID">
                <b-col cols="1" class="border font-italic p-50 text-center">{{ i + 1 }}</b-col>
                <b-col cols="8" class="border font-italic font-weight-bolder p-50">{{ item.kalemAdi }}</b-col>
                <b-col cols="1" class="border font-italic text-center p-50">{{ item.miktar }}</b-col>
                <b-col cols="2" class="border font-italic text-center font-weight-bolder p-50">{{
                    item.birimTuru
                  }}
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <h6 class="">Yüklenicinin malın tamamını, İdareye teslim edildiği tarihten malın kabulüne kadar
                geçen
                süreler için sigorta ettirmemesi halinde ortaya çıkan zararlar Yükleniciye aittir. </h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 15 –Muayene ve Kabul Şekil ve Şartları </h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">15.1 Denetim, Muayene Ve Kabul İşlemlerine, malın teslim edildiği gün içerisinde
                başlanması esastır. Muayene, teknik konularda uzman görüşüne göre ve Yetkilendirilen Alım Komisyonun
                tamamının katılımı ile yapılır. Yüklenici veya vekilinin hazır bulunması ile Komisyon tarafından,
                teslim
                edilen malların miktarı, malların ya da yapılan işin sözleşmeye uygunluğu bir kabul tutanağı ile
                tespit
                edilir. Tutanak sonucunda komisyon malların kalitesini, varsa hatalı ve kusurlu malların oranını ve
                adedini raporunda belirtir. Komisyon burada yazılanlarla sınırlı olmaksızın, varsa sözleşme dahilinde,
                ilave görüşlerine de raporunda yer verir. </h6>
            </b-col>
            <b-col cols="12">
              <h6 class="">15.2. Her kalem malzeme bir kısım olarak değerlendirildiğinden, idare niteliklerini
                uygun bulduğu kısımları kabul ederek bu kısımlara ait kısmi ödeme yapmakta serbesttir.</h6>
            </b-col>
            <b-col cols="12">
              <h6 class="">15.3 Teslim edilen malın Yetkilendirilen Alım Komisyonunca uygun bulunmaması halinde;
                malın değiştirilmesi / hata ve eksikliğin tamamlatılması veya alımın o aşamada iptal edilmesi idarenin
                yetkisindedir. Bu durumda Yüklenici hiçbir hak talebinde bulunamaz.
              </h6>
            </b-col>
            <b-col cols="12">
              <h6 class="">15.4. Alım onayında muayenenin Teknik Şartnamesine göre ve Muayene Komisyonunca
                yapılacağı belirtilen muayeneler TSK Mal Alımları Denetim, Muayene ve Kabul İşlemleri Yönergesine göre
                yapılacaktır.</h6>
            </b-col>
            <b-col cols="12">
              <h6 class="">15.5. Teslim edilen malın muayene sonucunda reddedilmesi halinde yüklenici muayene
                sonucunun kendisine tebliğ edildiği tarihi takip eden günden itibaren 5(beş) iş günü içerisinde malı
                bulunduğu yerden teslim alıp kaldırmak zorundadır. Aksi halde geçen her gün için sözleşme bedelinin
                %0,05 (onbindebeş) oranında ceza istihkakından kesilecektir</h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 16 – Kabulden Sonraki Hata Ve Ayıplardan
                Sorumluluk</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Yüklenicinin bu sözleşme gereği teslim ettiği mallarda, malzemenin hileli olmasından
                veya malın teknik gereklerine uygun imal edilmemiş olmasından dolayı zarar ve kayıplar oluştuğu
                takdirde
                bu zarar ve kayıplar, Yükleniciye tamamlattırılır veya ödettirilir.</h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 17 – Onay Verilmesinin Sorumluluğu Ortadan
                Kaldırmaması</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Herhangi bir işin üretim aşamasında ya da teslim öncesi imalat aşamasında denetlenmiş
                olması veya işe onay verilmiş olması, yüklenicinin üstlenmiş olduğu işi bütünüyle projelerine,
                sözleşme
                ve şartnamelerine, teknik ve sanat kurallarına uygun olarak yapmak hususundaki yükümlülüklerini ve bu
                konudaki sorumluluğunu ortadan kaldırmaz. </h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 18 – Sözleşmenin Feshi :</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">18.1 Sözleşme yapıldıktan sonra Yüklenicinin taahhüdünden vazgeçmesi veya taahhüdünü,
                sözleşme hükümlerine uygun olarak yerine getirmemesi halinde, idare sözleşmeyi fesheder.</h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 19 – Anlaşmazlıkların Çözümü</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">19.1.Bu sözleşme ve eklerinin uygulanmasındandan doğabilecek her türlü anlaşmazlığın
                çözümünde Amasya mahkemeleri ve icra daireleri yetkilidir.</h6>
            </b-col>
            <b-col cols="12">
              <h6 class="">19.2. İleride doğabilecek hukuki ihtilaflarda sözleşme ve eklerinin
                {{ $store.getters.FIRMA_BILGILERI.unvan }} VE
                ASO Müdürlüğündeki onaylı asıl suretleri (aslı gibidir) geçerli olacaktır.</h6>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 20 – Hüküm Bulunmayan Haller</h5>
            </b-col>
            <b-col cols="12">
              <h6 class="">Bu sözleşme ve eklerinde hüküm bulunmayan hallerde, ilgisine göre 4734 Sayılı Kanun ve
                4735 Sayılı Kanun hükümlerine, bu Kanunlarda hüküm bulunmaması halinde ise genel hükümlere göre
                hareket
                edilir.</h6>
            </b-col>
            <b-col cols="12">
              <h5 class=" font-size-14">Madde 21– Bu sözleşme taraflarca imzalandığı tarihte yürürlüğe
                girer. </h5>
            </b-col>
            <b-col cols="12">
              <h5 class="font-weight-bolder  font-size-14">Madde 22– Bu sözleşme 22 (yirmi iki) maddeden ibaret
                olup, İdare ve
                Yüklenici tarafından tam olarak okunup anlaşıldıktan sonra {{ bilgiler.belgeTarih }} tarihinde imza
                altına
                alınarak 1
                (bir) adet düzenlenmiş olup onaylı bir örneği yükleniciye teslim edilmiştir.</h5>
            </b-col>
            <b-col cols="6" class="mt-5 d-grid justify-content-center">
              <h5 class="text-decoration-underline d-block text-center">Yüklenici</h5>
              <h6 class="text-decoration-underline d-block text-center">{{ bilgiler.belgeTarih }} </h6>
            </b-col>
            <b-col cols="6" class="mt-5 d-grid justify-content-center">
              <h5 class="text-decoration-underline d-block text-center">İdare</h5>
              <h6 class="text-decoration-underline d-block text-center">{{ bilgiler.belgeTarih }}</h6>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
    <b-modal
        id="evrak12"
        ref="modal12"
        centered
        size="xl"
        hide-footer
        v-if="teklifler.find(x => x.firmaBilgileri.firmaID == bilgiler.firmaID)"
    >
      <b-button
          v-if="show"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="download('teslim-alma')"
          class="float-right border-0"
      >
        İndir yada Yazdır
      </b-button>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="false"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          filename="Malzeme Talep Formu"
          :pdf-quality="pdfQuality"
          :manual-paginaton="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          ref="teslim-alma"
          id="html2Pdf"
      >
        <section slot="pdf-content" class=" pl-2 pr-2">
          <b-row class="p-2 pr-5 pl-5" v-if="show">
            <b-col cols="12" class="mb-2">
              <h3 class="text-center font-size-17 mt-4">MUAYENE ve TESLİM ALMA KOMİSYON TUTANAĞI</h3>
            </b-col>
            <b-col cols="12">
              <p class="my-50 font-size-14">{{ bilgiler.belgeTarih }} tarihli ve {{ bilgiler.belgeTarihSayi }}
                sayılı {{ detay.ihaleUsulu }} ile
                {{
                  teklifler.find(x => x.firmaBilgileri.firmaID == bilgiler.firmaID).firmaBilgileri.firmaAdi
                }}
                ’nden
                satın alınan aşağıdaki tabloda cinsi ve miktarı belirtilen malzemelerin muayenesi ve kontrolü
                komisyonumuzca yapılmış olup, {{ bilgiler.belgeTarih }} tarihinde eksiksiz olarak teslim
                alınmıştır. </p>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-row>
                <b-col cols="1" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Sıra No
                  </h5>
                </b-col>
                <b-col cols="8" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Malzeme Adı
                  </h5>
                </b-col>
                <b-col cols="3" class="border">
                  <h5 class="mb-0 p-50 text-center font-weight-bolder font-size-13">
                    Miktarı
                  </h5>
                </b-col>
              </b-row>
              <b-row v-for="(item,i) in kalemler" :key="item.kalemID">
                <b-col cols="1" class="border">
                  <h6 class="mb-0 p-50 font-weight-bolder text-center font-size-14">
                    {{ i + 1 }}
                  </h6>
                </b-col>
                <b-col cols="8" class="border">
                  <h6 class="mb-0 p-50 font-weight-bolder font-size-14">
                    {{ item.kalemAdi }}
                  </h6>
                </b-col>
                <b-col cols="3" class="border">
                  <h6 class="mb-0 p-50 text-center font-size-14">
                    {{ item.miktar }} {{ item.birimTuru }}
                  </h6>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="pb-5 pt-4">
              <b-row>
                <b-col v-for="uye in lodash.orderBy(evraklar( 11).evrakUyeleri, 'sira', 'asc')"
                       :key="uye.siraNo"
                       class="mt-1"
                >
                  <h6 class="mb-1 text-center text-decoration-underline font-size-14">{{ uye.gorev }}</h6>
                  <p class="m-0 text-center font-weight-bolder font-size-14">{{ uye.adSoyad }}</p>
                  <p class="m-0 text-center font-weight-bold font-size-14">{{ uye.unvan }}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" class="text-center pt-3">
              <p class="p-0 m-0 font-size-14">Bilgim Vardır.</p>
            </b-col>
            <b-col cols="12" class="text-center ">
              <p class="p-0 m-0 font-size-14">{{ bilgiler.belgeTarih }}</p>
            </b-col>
            <b-col cols="12" class="text-center" v-if="bilgiler.ihaleYetkilisi">
              <p class="pt-50 m-0 font-weight-bolder font-size-14">{{ bilgiler.ihaleYetkilisi }}</p>
            </b-col>
            <b-col cols="12" class="text-center" v-if="bilgiler.yetkiliUnvan">
              <p class="p-0 m-0 font-size-14">{{ bilgiler.yetkiliUnvan }}</p>
            </b-col>
          </b-row>
          <b-row v-if="!show">
            <b-col cols="12">
              <h4 class="text-center">Bekleyiniz...</h4>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-modal>
  </section>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCol,
  BCollapse,
  BContainer, BDropdown, BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea, BImg,
  BInputGroup,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs,
  VBTooltip,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import axios from 'axios'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import VueHtml2pdf from 'vue-html2pdf'
import _ from 'lodash'
import flatpickr from 'flatpickr'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr
flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  components: {
    VueHtml2pdf,
    BCollapse,
    BDropdownItem,
    BDropdown,
    BImg,
    BForm,
    vSelect,
    BFormInvalidFeedback,
    BPagination,
    BInputGroupPrepend,
    BTab,
    BTabs,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormTextarea,
    flatPickr,
    BCard,
    BCardText,
    BContainer,
    BFormSelect,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
  },
  data() {
    return {
      lodash: _,
      show: false,
      tableData: [],
      teklifKalemleri: [],
    }
  },
  props: ['bilgiler', 'detay', 'uyeler', 'kalemler', 'teklifler'],
  computed: {
    currentYear() {
      const date = new Date()
      return date.getFullYear()
    },
    enAvantajliTeklif() {
      if (this.teklifler) {
        const avantajList = []
        this.teklifler.forEach(item => {
          const ortalama = _.meanBy(_.flatMap(item.teklifler, 'toplamFiyat'), o => +o)
          avantajList.push({
            name: item.firmaBilgileri.firmaUnvan,
            teklif: ortalama,
          })
        })
        return _.orderBy(avantajList, 'teklif', 'asc')
      } else {
        return false
      }
    },
    mevcutGun() {
      const date = new Date()
      const yil = date.getFullYear()
      const ay = String(date.getMonth() + 1)
          .padStart(2, '0')
      const gun = String(date.getDate())
          .padStart(2, '0')
      return `${gun}.${ay}.${yil}`
    },
  },
  methods: {
    formatFirmaBilgileri(firmaBilgileri) {
      const {
        // firmaAdi,
        firmaUnvan,
      } = firmaBilgileri
      let formattedFirmaBilgileri = ''
      // if (firmaAdi && firmaAdi.length > 10) {
      //     formattedFirmaBilgileri += this.breakLines(firmaAdi)
      // } else {
      //     formattedFirmaBilgileri += firmaAdi
      // }
      if (firmaUnvan && firmaUnvan.length > 10) {
        formattedFirmaBilgileri += this.breakLines(firmaUnvan)
      } else {
        formattedFirmaBilgileri += firmaUnvan
      }
      return formattedFirmaBilgileri
    },
    breakLines(text) {
      let formattedText = ''
      const words = text.split(' ')
      for (let i = 0; i < words.length; i++) {
        formattedText += `${words[i]} `
        if ((i + 1) % 10 === 0) {
          formattedText += '<br>'
        }
      }
      return formattedText
    },
    ortalamaFiyat(kalemID) {
      const data = this.teklifler.map(x => x.teklifler.find(y => y.kalemID === kalemID))
      return _.meanBy(_.flatMap(data, 'birimFiyat'), o => +o)
    },
    ortalamaFiyatBirim(kalemID) {
      const data = this.teklifler.map(x => x.teklifler.find(y => y.kalemID === kalemID))
      return _.meanBy(_.flatMap(data, 'birimFiyat'), o => +o)
    },
    ortalamaFiyatToplam(kalemID) {
      const data = this.teklifler.map(x => x.teklifler.find(y => y.kalemID === kalemID))
      return _.meanBy(_.flatMap(data, 'toplamFiyat'), o => +o)
    },
    nihaiFiyat(kalemID) {
      const data = this.teklifler.map(x => x.teklifler.find(y => y.kalemID === kalemID))
      const ortalama = _.meanBy(_.flatMap(data, 'birimFiyat'), o => +o)
      return ortalama * data[0].miktar
    },
    totalFiyat() {
      const kalemler = this.kalemler.map(x => x.kalemID)
      const increment = kalemler.map(kalem => {
        const data = this.teklifler.map(x => x.teklifler.find(y => y.kalemID === kalem))
        const ortalama = _.meanBy(_.flatMap(data, 'birimFiyat'), o => +o)
        return ortalama * data[0].miktar
      })
      return _.sum(increment)
    },
    genelToplamKDVHaric() {
      const kalemler = this.kalemler.map(x => x.kalemID)
      const increment = kalemler.map(kalem => {
        const data = this.teklifler.map(x => x.teklifler.find(y => y.kalemID === kalem))
        const ortalama = _.meanBy(_.flatMap(data, 'birimFiyat'), o => +o)
        return ortalama * data[0].miktar
      })
      return _.sum(increment)
    },
    ortalamaKDV() {
      const kalemler = this.kalemler.map(x => x.kalemID)
      const ortalamaKDV = kalemler.map(kalem => {
        const data = this.teklifler.map(x => x.teklifler.find(y => y.kalemID === kalem))
        const ortalama = _.meanBy(_.flatMap(data, 'birimKDV'), o => +o)
        return ortalama * data[0].miktar
      })
      return this.genelToplamKDVHaric() / 100 * 18
      // return this.genelToplamKDVHaric() / 100 * _.sum(ortalamaKDV)
    },
    ortalamaKDVDahil() {
      return this.genelToplamKDVHaric() + this.ortalamaKDV()
    },
    yaklasikMaliyetTeklif(kalemID, firmaIndex) {
      const firma = this.teklifler[firmaIndex]
      if (firma) {
        const kalem = firma.teklifler.find(x => x.kalemID == kalemID)
        return kalem
      }
      return false
    },
    evraklar(evrakNo) {
      const evrakUyeleri = this.uyeler.filter(evrak => evrak.evrakNo == evrakNo)
      const sira = []
      evrakUyeleri.forEach(item => {
        sira[`sira${item.sira}`] = item
      })
      return {
        sira,
        evrakUyeleri,
      }
    },
    download(ref) {
      this.$refs[ref].generatePdf()
    },
  },
  mounted() {
    // Todo Modal Acilinca PDF Duzenlenmesi Icin Tarayıcı Design Mode Geciyor
    const modals = ['modal1', 'modal2', 'modal3', 'modal4', 'modal5', 'modal6', 'modal7', 'modal8', 'modal9', 'modal10', 'modal11', 'modal12']
    modals.forEach(modal => {
      if (this.$refs[modal] != undefined) {
        this.$refs[modal].$on('hidden', () => {
          document.designMode = 'off'
        })
        this.$refs[modal].$on('shown', () => {
          document.designMode = 'on'
        })
      }
    })
  },
  created() {
    if (this.uyeler) {
      setTimeout(() => {
        this.show = true
      })
    }
  },
  beforeDestroy() {
    console.clear()
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>

<style lang="scss">
.custom-box {
  height: 20px;
  width: 20px;
  border: 1px solid grey;
  border-radius: 5px;
  display: inline-block;
  transform: translate(10px, 2px);
}

.custom-box-checked {
  height: 20px;
  width: 20px;
  border: 1px solid grey;
  border-radius: 5px;
  display: inline-block;
  transform: translate(10px, 2px);
  position: absolute;
}

.custom-box-checked::after {
  position: relative;
  content: url('../../../assets/images/buro/material/check-icon.png');
  top: -10px;
}

.evrak4-firmalar {
  writing-mode: vertical-rl !important;
  transform: rotate(180deg);
  padding: 5px !important;
  //max-height: 25%;
  text-align: center;
}

.layout-container .content-wrapper {
  display: contents !important;
}

#html2Pdf {
  margin: 0% 15%;
}

.text-decoration-underline {
  text-decoration: underline;
}

.other-organizasyon {
  height: 35px;
}

</style>
<style>

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-19 {
  font-size: 19px !important;
}

#evrak1 p {
  font-size: 12px !important;
  margin: 0 !important;
  padding: 0 !important;
}

.p-25 {
  padding: 0.25rem;
}

@media screen {
  .border {
    border: 1px solid #dbd8e3 !important;
  }
}
</style>
